import React from 'react';
import Navigation from '../components/Navigation';
import PrivacyPolicy from './PrivacyPolicy';
import Footer from '../components/Footer';


const Policy = () => {
  return (
    <div>
      <Navigation />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
};

export default Policy;
