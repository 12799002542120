import React, { useEffect, useState }  from 'react'
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

function CookiePolicy() {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    fetch('./CookiePolicy.html')
      .then(response => response.text())
      .then(content => {
        console.log('content: ', content);
        setHtmlContent(content);
      });
  }, []);

  return (
    <div>
      <Navigation />
      <div className="flex justify-center">
        <div className="w-full max-w-screen-md pt-10 pb-10">
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CookiePolicy