import React from 'react';

const Features = () => {
  return (
    <div className='bg-gray-100' id='features'>
      {/* <!-- Icon Blocks --> */}
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 items-center gap-12">
          {/* <!-- Icon Block --> */}
          <div className="text-center">
            <div className="flex justify-center items-center w-12 h-12 bg-blue-600 border border-gray-200 rounded-full mx-auto dark:bg-gray-800 dark:border-gray-700 ">
              <svg className="w-5 h-5 text-white dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
            </div>
            <div className="mt-3">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">50M+ candidates</h3>
              <p className="mt-1 text-gray-600 dark:text-gray-400">We’ve accumulated the most relevant pool of diverse candidates from Github spanning 50+ countries and 10,000+ verified tech skills.</p>
            </div>
          </div>
          {/* <!-- End Icon Block --> */}

          {/* <!-- Icon Block --> */}
          <div className="text-center">
            <div className="flex justify-center items-center w-12 h-12 bg-blue-600 border border-gray-200 rounded-full mx-auto dark:bg-gray-800 dark:border-gray-700">
              <svg className="w-5 h-5 text-white dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M9.465 10H12a2 2 0 1 1 0 4H9.465c.34-.588.535-1.271.535-2 0-.729-.195-1.412-.535-2z" />
                <path d="M6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 1a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm.535-10a3.975 3.975 0 0 1-.409-1H4a1 1 0 0 1 0-2h2.126c.091-.355.23-.69.41-1H4a2 2 0 1 0 0 4h2.535z" />
                <path d="M14 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0z" />
              </svg>
            </div>
            <div className="mt-3">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Smart AI Matching</h3>
              <p className="mt-1 text-gray-600 dark:text-gray-400">Insert a job description and get the most relevant developers based on their open source-code contributions, automagically!</p>
            </div>
          </div>
          {/* <!-- End Icon Block --> */}

          {/* <!-- Icon Block --> */}
          <div className="text-center">
            <div className="flex justify-center items-center w-12 h-12 bg-blue-600 border border-gray-200 rounded-full mx-auto dark:bg-gray-800 dark:border-gray-700">
              <svg className="w-5 h-5 text-white dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
              </svg>
            </div>
            <div className="mt-3">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Candidate insights</h3>
              <p className="mt-1 text-gray-600 dark:text-gray-400">Uncover the candidate's email and social links as well as their coding skills, project experience, and contribution history to make informed hiring decisions.</p>
            </div>
          </div>
          {/* <!-- End Icon Block --> */}

          {/* <!-- Icon Block --> */}
          <div className="text-center">
            <div className="flex justify-center items-center w-12 h-12 bg-blue-600 border border-gray-200 rounded-full mx-auto dark:bg-gray-800 dark:border-gray-700">
              <svg className="w-5 h-5 text-white dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </div>
            <div className="mt-3">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Automated outreach <span style={{fontWeight: 400}}><sup>coming soon</sup></span></h3>
              <p className="mt-1 text-gray-600 dark:text-gray-400">Design AI-generated personalized email outreach campaigns that can leave a positive first impression and warm up those passive candidates</p>
            </div>
          </div>
          {/* <!-- End Icon Block --> */}
        </div>
      </div>
      {/* <!-- End Icon Blocks --> */}
    </div>
  );
};

export default Features;
