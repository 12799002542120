import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Home from './components/Home';
import Policy from './policy/Policy';
import CookiePolicy from './cookiePolicy/CookiePolicy';

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/policies" element={<Policy />} />
        <Route path="/cookie_policies" element={<CookiePolicy />} />

        {/* <Route path="/candidates" element={<CategoryFilters />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/candidate-profile/:username" element={<CandidateDetail />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
