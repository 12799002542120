import React, { useRef } from 'react';

function Header({ inputRef }: {inputRef: any}) {


  return (
    <div style={{ margin: "75px 0px" }} id="home">
      {/* <!-- Hero --> */}
      <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
        {/* <!-- Grid --> */}
        <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
          <div>
            <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight dark:text-white">Start sourcing candidates from <span className="text-blue-600">Github</span> like a pro</h1>
            {/* <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">We use AI to analyze open source code contributions to identify and connect you with talented developers who precisely match your job requirements.</p> */}

            {/* <!-- Buttons --> */}
            {/* <div className="mt-7 grid gap-3 w-full sm:inline-flex items-center">
              <a className="inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800" href="https://beta.repomatch.io/">
                Start for Free
                <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                </svg>
              </a>
            </div> */}
            {/* <div className="mt-4 flex flex-col items-center gap-2 sm:flex-row sm:gap-3 bg-white rounded-md p-2">
              <div className="w-full">
                <label htmlFor="hero-input" className="sr-only">Search</label>
                <input type="text" id="hero-input" name="hero-input" className="py-3 px-4 block w-full border-transparent shadow-sm rounded-md focus:z-10 focus:border-blue-500 focus:ring-blue-500 max-w-xs" placeholder="Enter your email" />
              </div>
              <a className="w-full sm:w-auto whitespace-nowrap inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4" href="#">
              Join the Waitlist
            </a>
            </div> */}

            <div className="mt-4 lg:mt-8 flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
              <div className="w-full sm:w-auto">
                <label htmlFor="hero-input" className="sr-only">Search</label>
                <input ref={inputRef} type="text" id="hero-input" name="hero-input" className="py-3 px-4 block w-full xl:min-w-[18rem] border-gray-200 shadow-sm rounded-md focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" style={{ border: "1px solid rgb(229 231 235)" }} placeholder="Enter your email" />
              </div>
              <a className="w-full sm:w-auto inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800" href="#">
                Join the Waitlist
              </a>
            </div>

            {/* <!-- End Buttons --> */}

          </div>
          {/* <!-- End Col --> */}

          <div className="relative ml-4">
            <img className="w-full rounded-md" src="images/intro-bg.png" alt="Image Description" />
            {/* <div className="absolute inset-0 -z-[1] bg-gradient-to-tr from-gray-200 via-white/0 to-white/0 w-full h-full rounded-md mt-4 -mb-4 mr-4 -ml-4 lg:mt-6 lg:-mb-6 lg:mr-6 lg:-ml-6 dark:from-slate-800 dark:via-slate-900/0 dark:to-slate-900/0"></div> */}

            {/* <!-- End SVG--> */}
          </div>
          {/* <!-- End Col --> */}
        </div>
        {/* <!-- End Grid --> */}
      </div>
      {/* <!-- End Hero --> */}
    </div>
  )
}

export default Header;